import { CSSProperties, FC } from 'react'
import './index.less'

type Props = {
  margin?: string
  classname?: string
  style?: CSSProperties
}

const CustomDivider: FC<Props> = ({ margin = "32px 0", classname, style }) => (
  <div className={`custom-divider ${classname}`} style={{ ...style, margin }} />
)

export default CustomDivider