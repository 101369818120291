import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header, Loader } from "."

const Layout: FC = () => (
  <BaseLayout>
    <Header />

    <BaseLayout>
      <Suspense fallback={<Loader spinning={true} />}>
        <Outlet />
      </Suspense>
    </BaseLayout>
    
    <Footer />
  </BaseLayout>
)

export { Layout }
