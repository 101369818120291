import { Button, Col, Drawer, Row } from "antd"
import { FC } from "react"
import './MobileMenu.less'
import { ReactComponent as Close } from '../../../assets/icons/xmark.svg'
import { ReactComponent as Logo } from '../../../assets/icons/main_logo.svg'
import CustomDivider from "src/components/ui/CustomDivider/CustomDivider"

type Props = {
  isOpen: boolean
  onMenuClose: React.Dispatch<React.SetStateAction<boolean>>
  menuItems: ComponentUiLink[]
}

export const MobileMenu: FC<Props> = ({ isOpen, menuItems, onMenuClose }) => {
  const handleCloseMenu = () => (
    onMenuClose(prevState => !prevState)
  )

  return (
    <Drawer open={isOpen} onClose={handleCloseMenu} rootClassName={'drawer'} closeIcon={<Close />}>
      <nav className={'burger-menu-nav'}>
        <ul className={'nav-list'}>
          {menuItems && menuItems.map((item) => (
            <li key={item.id} className={'nav-link-wrapper'}>
              <Button href={item.url} type={'link'} target={item.target} className={'nav-link'}>
                {item.title}
              </Button>
            </li>
          ))}
        </ul>
      </nav>

      <Row>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <Button href={'/'} style={{ height: 'fit-content', border: 'none', background: 'none' }}>
                <Logo />
              </Button>
            </Col>

            <Col span={12} style={{ textAlign: 'end' }}>
              <Button className={'fotter-link'} target={'_blank'} href={'/disclosure'}>
                Disclosure
              </Button>

              <Button className={'fotter-link'} href={'/#top-100-companies'}>
                Top 10 Companies
              </Button>
            </Col>
          </Row>

          <CustomDivider classname={'footer-divider'} />

          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Button className={'fotter-link'}>
                All Righs Reserved
              </Button>
            </Col>

            <Col>
              <Button className={'fotter-link'} href={"mailto:info@goldco.com"}>
                info@goldco.com
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Drawer>
  )
}