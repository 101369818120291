import { useLocation } from "react-router"
import { ReactComponent as Logo } from '../../../assets/icons/main_logo.svg'
import { ReactComponent as BurgerIcon } from '../../../assets/icons/burger_menu_icon.svg'
import { ReactComponent as GoldenLogo } from '../../../assets/icons/main_logo_gold.svg'
import { Button } from "antd"
import './DesktopMenu.less'
import { useBreakpoint } from "src/hooks/useBreakpoint"
import { FC, useEffect, useState } from "react"
import { Link } from "react-router-dom"

type Props = {
  onMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  menuItems: ComponentUiLink[]
}

export const DesktopMenu: FC<Props> = ({ onMenuOpen, menuItems }) => {
  const path = useLocation()
  const { lg } = useBreakpoint()

  const [prevScrollY, setPrevScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      setIsVisible(prevScrollY > currentScrollY || currentScrollY < 10);
      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  const handleOpenMenu = () => (
    onMenuOpen(prevstate => !prevstate)
  )

  const homePath = path.pathname === '/'
    
  return (
    <div className={`header-menu ${isVisible ? "visible" : "hidden"} `}>
      {homePath ? (
        <a className={'header-logo-wrapper'} href={'/'} target={'_self'}>
          {prevScrollY > 800 ? <GoldenLogo /> : <Logo />}
        </a>

      ) : (
        <a className={'header-logo-wrapper'} href={'/'} target={'_self'}>
          <GoldenLogo />
        </a>
      )
    }
      {!lg ? (
        <Button onClick={handleOpenMenu} className={'burger-button'}>
          <BurgerIcon />
        </Button>
      ) : (
        <nav className={'nav-block'}>
          <ul className={'nav-list'}>
            {menuItems?.map((item) => (
              <li key={item?.id} className={'nav-link-wrapper'}>
                <Link to={item?.url} className={"nav-link"}>
                  {item?.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  )
}