import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"

import DefaultLayout, { Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const Company = lazy<FC>(() => import("./company"))
const AboutUs = lazy<FC>(() => import('./about-us'))
const Disclosure = lazy<FC>(() => import('./disclosure'))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: AboutUs,
        path: "/about-us"
      },
      {
        Component: Disclosure,
        path: '/disclosure'
      },
      {
        Component: Company,
        path: "/:slug",
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

export default routes
