import { Button, Col, Row } from "antd"
import { FC } from "react"
import { ReactComponent as Logo } from '../../assets/icons/main_logo.svg'
import CustomButton from "../ui/CustomButton/CustomButton"
import CustomDivider from "../ui/CustomDivider/CustomDivider"
import './Footer.less'
import { useBreakpoint } from "src/hooks/useBreakpoint"

const Footer: FC = () => {
  const { xs } = useBreakpoint()
  return (
    <Row className={'footer'} justify={'center'} style={{ backgroundColor: '#121213', zIndex: '1' }}>
      <Col span={24} style={{ maxWidth: '1600px' }}>
        <Row justify={xs ? 'space-between' : 'space-around'} align={'middle'}>
          <Col md={2}>
            <Logo />
          </Col>

          <Col 
            md={14} 
            xs={{ span: 8 }} 
            style={{ 
              textAlign: xs ? 'end' : 'initial', 
              display: 'flex', 
              gap: xs ? '0' : '32px',
              flexDirection: xs ? 'column' : 'row' 
            }}
          >
            <Button className={'fotter-link'} target={'_blank'} href={'/disclosure'}>
              Disclosure
            </Button>

            <Button className={'fotter-link'} href={'/#top-100-companies'}>
              Top 10 Companies
            </Button>
          </Col>

          {!xs && <Col span={8}>
            <CustomButton classname={'about-button'} type={'gold'} title={'About Us'} url={'/about-us'} target={'blank'} />
          </Col>}
        </Row>

        <CustomDivider classname={'footer-divider'} />

        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Button className={'fotter-link'}>
              All Righs Reserved
            </Button>
          </Col>

          <Col>
            <Button className={'fotter-link'} href={"mailto:info@goldco.com"}>
              info@goldco.com
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export { Footer }
