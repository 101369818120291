import { Row } from "antd"
import { FC, useState } from "react"
import { DesktopMenu } from "../menu/DesktopMenu"
import { MobileMenu } from "../menu/MobileMenu"
import { useLayoutQuery } from "src/graphql"

const Header: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { headerMenu } = useLayoutQuery().data?.layout?.data?.attributes || {}

  return (
    <Row>
      {isMenuOpen && <MobileMenu menuItems={headerMenu as ComponentUiLink[]} onMenuClose={setIsMenuOpen} isOpen={isMenuOpen} />}
      <DesktopMenu menuItems={headerMenu as ComponentUiLink[]} onMenuOpen={setIsMenuOpen} />
    </Row>
  )
}

export { Header }
