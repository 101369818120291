import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Company",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiArticleSection",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "CriticalFactor",
      "Disclosure",
      "Home",
      "Layout",
      "QnA",
      "ReactIconsIconlibrary",
      "TopIraCompany",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Company",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiArticleSection",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "CriticalFactor",
      "Disclosure",
      "Home",
      "Layout",
      "QnA",
      "ReactIconsIconlibrary",
      "TopIraCompany",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const ArticleSectionFragmentDoc = gql`
    fragment ArticleSection on ComponentUiArticleSection {
  id
  title
  text
  button {
    ...Link
  }
}
    `;
export const CompanyFullFragmentDoc = gql`
    fragment CompanyFull on Company {
  name
  subtitle
  logo {
    data {
      ...File
    }
  }
  full_article_section(pagination: {limit: 50}) {
    ...ArticleSection
  }
  email
  phone
  website
  address
  slug
}
    `;
export const CompanyShortFragmentDoc = gql`
    fragment CompanyShort on Company {
  name
  logo {
    data {
      ...File
    }
  }
  short_article
  slug
}
    `;
export const CriticalFactorFragmentDoc = gql`
    fragment CriticalFactor on CriticalFactor {
  title
  article
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const QnAFragmentDoc = gql`
    fragment QnA on QnA {
  question
  answer
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const TextFragmentDoc = gql`
    fragment Text on ComponentUiText {
  id
  visible
  children {
    value
  }
}
    `;
export const SetFragmentDoc = gql`
    fragment Set on ComponentDataSet {
  id
  value
}
    `;
export const TopCompaniesCardFragmentDoc = gql`
    fragment TopCompaniesCard on TopIraCompany {
  title
  name
  website
  option {
    ...Set
  }
  card_button {
    ...Link
  }
  slug
}
    `;
export const DisclosureDocument = gql`
    query disclosure {
  disclosure {
    data {
      attributes {
        article
      }
    }
  }
}
    `;
export function useDisclosureQuery(baseOptions?: Apollo.QueryHookOptions<DisclosureQuery, DisclosureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisclosureQuery, DisclosureQueryVariables>(DisclosureDocument, options);
      }
export function useDisclosureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisclosureQuery, DisclosureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisclosureQuery, DisclosureQueryVariables>(DisclosureDocument, options);
        }
export type DisclosureQueryHookResult = ReturnType<typeof useDisclosureQuery>;
export type DisclosureLazyQueryHookResult = ReturnType<typeof useDisclosureLazyQuery>;
export type DisclosureQueryResult = Apollo.QueryResult<DisclosureQuery, DisclosureQueryVariables>;
export const CompanyDocument = gql`
    query company($slug: String!) {
  companies(filters: {slug: {eq: $slug}}, pagination: {limit: 100}) {
    data {
      attributes {
        ...CompanyFull
      }
    }
  }
}
    ${CompanyFullFragmentDoc}
${FileFragmentDoc}
${ArticleSectionFragmentDoc}
${LinkFragmentDoc}`;
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        top_10_title
        top_10_description
        read_disclosure_title
        read_full_disclosure {
          ...Link
        }
        top_ira_companies {
          data {
            attributes {
              ...TopCompaniesCard
            }
          }
        }
        companies {
          data {
            attributes {
              ...CompanyShort
            }
          }
        }
        critical_factors {
          data {
            attributes {
              ...CriticalFactor
            }
          }
        }
        bottom_line_section {
          ...Text
        }
        uncover_the_best {
          ...Headline
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${TopCompaniesCardFragmentDoc}
${SetFragmentDoc}
${CompanyShortFragmentDoc}
${CriticalFactorFragmentDoc}
${TextFragmentDoc}
${HeadlineFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        headerMenu {
          ...Link
        }
      }
    }
  }
}
    ${LinkFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const QnaDocument = gql`
    query qna {
  qnAs {
    data {
      attributes {
        ...QnA
      }
    }
  }
}
    ${QnAFragmentDoc}`;
export function useQnaQuery(baseOptions?: Apollo.QueryHookOptions<QnaQuery, QnaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QnaQuery, QnaQueryVariables>(QnaDocument, options);
      }
export function useQnaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QnaQuery, QnaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QnaQuery, QnaQueryVariables>(QnaDocument, options);
        }
export type QnaQueryHookResult = ReturnType<typeof useQnaQuery>;
export type QnaLazyQueryHookResult = ReturnType<typeof useQnaLazyQuery>;
export type QnaQueryResult = Apollo.QueryResult<QnaQuery, QnaQueryVariables>;