import { Button } from "antd";
import { CSSProperties, FC } from "react";
import './index.less'
import { Link } from "react-router-dom";

type ButtonType = 'black' | 'transparent' | 'gold';

type Props = {
  title: Maybe<string> 
  url: Maybe<string> 
  target: Maybe<EnumComponentuilinkTarget>
  type?: ButtonType
  classname?: string
  style?: CSSProperties
}

const CustomButton: FC<Props> = ({ style, title, url, target, type = 'gold', classname }) => (
  <Button
    style={{ ...style, lineHeight: '18px' }} 
    target={target || ''} 
    className={`custom-button ${classname} ${type}`}
  >
    <Link to={url || ''} className={'custom-button-title'}>
      {title}
    </Link>
  </Button>
)



export default CustomButton